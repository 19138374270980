import { HeroLayout } from "./HeroLayout";
import { LiveLiveStatus } from "~/lib/client/live-status";
import { useRecentViewers } from "~/lib/client/useRecentViewers";
import React, { FC } from "react";

export const OnAirHero: FC<{ liveStatus: LiveLiveStatus }> = ({
  liveStatus,
}) => {
  const { loading, recentViewers } = useRecentViewers(liveStatus.video.id);
  return (
    <HeroLayout statusText="On-Air" statusColor="red" video={liveStatus.video}>
      Sunday @ 10am PT &nbsp; &nbsp;
      {loading || recentViewers < 5
        ? null
        : `${recentViewers} ${recentViewers === 1 ? "dancer" : "dancers"}`}
    </HeroLayout>
  );
};
