import { motion } from "~/lib/client/framer";
import React from "react";

export const FadeIn: React.FC<{ duration: number }> = ({
  children,
  duration,
}) => (
  <motion.div
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    transition={{ duration }}
  >
    {children}
  </motion.div>
);
