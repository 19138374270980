import { ImageField } from "@prismicio/types";
import Link from "next/link";
import React from "react";
import styles from "./LiveStreamModule.module.scss";

type LiveStreamProps = {
  image: ImageField;
};

export const LiveStreamModule = ({ image }: LiveStreamProps) => {
  if (image.url === null) {
    return null;
  }

  return (
    <section className={styles.live_stream_section}>
      <div className={styles.copy}>
        <div className={styles.title}>
          <h2 className={styles.hype}>
            <span className={styles.mobile_text}>Free</span> On-Air
          </h2>
          <h2>
            <span className={styles.desktop_text}>Free</span> Weekly Livestream
          </h2>
        </div>
        <p>
          Dance with community from coast to coast broadcasting live every
          Sunday at 10a PT
        </p>
        <Link href="/live-class">
          <a className={styles.desktop_link}>See all streaming classes →</a>
        </Link>
        <Link href="/live-class">
          <a className={styles.mobile_link}>Sign up to join each Sunday→</a>
        </Link>
      </div>
      <img src={image.url} className={styles.image} alt="livestream offline" />
    </section>
  );
};
