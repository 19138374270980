import { DefaultHero } from "./DefaultHero";
import { FadeIn } from "~/components/animation";
import { OnAirHero } from "./OnAirHero";
import { OnAirSoonHero } from "./OnAirSoonHero";
import { RichTextField } from "@prismicio/types";
import { useLiveStatus } from "~/lib/client/live/useLiveStatus";
import React from "react";
import styled from "styled-components";

const PlaceHolder = styled.div({
  height: "100vh",
});

const fadeDuration = 0.3;

type HeroProps = {
  heroText: RichTextField;
};

export const Hero = ({ heroText }: HeroProps) => {
  const liveStatus = useLiveStatus();

  if (liveStatus.status === "loading") return <PlaceHolder />;

  if (
    liveStatus.status === "none" ||
    (liveStatus.status === "upcoming" && liveStatus.upcomingStatus !== "soon")
  )
    return (
      <FadeIn duration={fadeDuration}>
        <DefaultHero heroText={heroText} />
      </FadeIn>
    );

  if (liveStatus.status === "upcoming") {
    return (
      <FadeIn duration={fadeDuration}>
        <OnAirSoonHero liveStatus={liveStatus} />
      </FadeIn>
    );
  }

  return (
    <FadeIn duration={fadeDuration}>
      <OnAirHero liveStatus={liveStatus} />
    </FadeIn>
  );
};
