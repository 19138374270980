import styled from "styled-components";

export const SectionTitle = styled.h2(
  ({ theme: { fontSizes, mediaQueries } }) => ({
    fontFeatureSettings: '"ss03" on',
    fontSize: fontSizes[6],
    letterSpacing: "-0.01em",
    lineHeight: 1,

    [mediaQueries.tablet]: {
      fontSize: fontSizes[8],
      lineHeight: 1,
    },

    [mediaQueries.desktop]: {
      fontSize: fontSizes[9],
      lineHeight: 0.9,
    },
  })
);
