import { Action, Label } from "~/lib/client/tracking";
import { CreateMembership } from "../common/SubscriptionForm/CreateMembership";
import { Elements } from "@stripe/react-stripe-js";
import { Modal } from "../common/Modal";
import { OfferingApi } from "dcgo-contracts";
import { stripeJs } from "~/lib/client/stripeLibrary";
import { useAccount } from "~/lib/client/account";
import { useBeginGiftOnboarding } from "../subscribe/useBeginGiftOnboarding";
import { useMembership } from "../common/SubscriptionForm/useMembership";
import { useRouter } from "next/router";
import { useSelectPlan } from "../common/SubscriptionForm/useSelectPlan";
import { useTrackView } from "~/lib/client/tracking/useTrackView";
import LoginForm from "./LoginForm";
import React, { useEffect, useState } from "react";
import SignupForm from "./SignupForm";

export const SubscribeForm = ({
  analyticsContext,
  selectedOffering,
}: {
  analyticsContext: Label;
  selectedOffering?: OfferingApi.Offering["id"];
}) => {
  const [membershipState, membershipActions] = useMembership(analyticsContext);
  useTrackView(Action.VIEWED_SUBSCRIBE_FORM, analyticsContext);
  const selectPlan = useSelectPlan({
    selectedOffering: selectedOffering ? { id: selectedOffering } : undefined,
  });

  const [selectPlanState] = selectPlan;
  const router = useRouter();

  useBeginGiftOnboarding({
    subscribedPath: router.asPath,
    label: analyticsContext,
    code: selectPlanState.code,
    codeType: selectPlanState.promotionType,
    shouldRedirect: true,
  });

  return (
    <Elements stripe={stripeJs}>
      <CreateMembership
        errorMessage={membershipState.errorMessage}
        createMembership={membershipActions.createMembership}
        selectedOffering={selectedOffering}
        selectPlan={selectPlan}
      />
    </Elements>
  );
};

export const RequireMembership = ({
  analyticsContext,
  dismissable = false,
  onClose,
  selectedOffering,
}: {
  analyticsContext: Label;
  dismissable?: boolean;
  onClose?: () => void;
  selectedOffering?: OfferingApi.Offering["id"];
}) => {
  const [
    {
      permissions: { canAccessVOD },
      isLoading,
      account: { uid },
    },
  ] = useAccount();
  const [mode, setMode] = useState<"login" | "signup" | "subscribe">(
    uid ? "subscribe" : "signup"
  );

  useEffect(() => {
    if (uid && mode !== "subscribe") {
      setMode("subscribe");
    }
  }, [uid, mode]);

  if (isLoading) return null;
  if (canAccessVOD) return null;

  return (
    <Modal
      dismissable={dismissable}
      onClose={onClose}
      label="Welcome to Dance Church"
    >
      {mode === "login" && (
        <LoginForm
          onSignup={() => {
            setMode("signup");
          }}
          analyticsContext={analyticsContext}
        />
      )}
      {mode === "signup" && (
        <SignupForm
          onLogin={() => {
            setMode("login");
          }}
          analyticsContext={analyticsContext}
        />
      )}
      {mode === "subscribe" && (
        <SubscribeForm
          analyticsContext={analyticsContext}
          selectedOffering={selectedOffering}
        />
      )}
    </Modal>
  );
};
