import { getRecentViewers } from "./api/view";
import { useEffect, useState } from "react";
import { useInterval } from "./interval";

const POLL_INTERVAL = 30 * 1000;

export const useRecentViewers = (assetId: number, interval = POLL_INTERVAL) => {
  const [viewers, setViewers] = useState<number | null>(null);

  useEffect(() => {
    getRecentViewers(assetId).then((r) => setViewers(r.recentViewers));
  }, [assetId]);

  useInterval(
    () => getRecentViewers(assetId).then((r) => setViewers(r.recentViewers)),
    interval
  );

  if (viewers === null) return { loading: true, recentViewers: 0 };
  return { loading: false, recentViewers: viewers };
};
