import { DateTime } from "luxon";
import { PrismicDocumentData } from "~/lib/shared/prismic";
import { PrismicRichText } from "@prismicio/react";
import { RichTextField } from "@prismicio/types";
import ArrowLeftIcon from "~/images/arrow-left-circle.svg";
import ArrowRightIcon from "~/images/arrow-right-circle.svg";
import Link from "next/link";
import React, { MouseEvent, useRef, useState } from "react";
import Slider from "react-slick";
import styles from "./BlogPostsModule.module.scss";

/* eslint-disable camelcase */
export type BlogPost = {
  uid: string;
  last_publication_date: string;
  data: {
    title: RichTextField;
    hero_image: {
      url: string;
    };
  };
};

type BlogPostsProps = {
  articles: BlogPost[];
};

/* eslint-enable camelcase */

export const BlogPostsModule = ({ articles }: BlogPostsProps) => {
  const sliderRef = useRef<Slider | null>(null);
  const [tap, setTap] = useState({ x: 0, y: 0 });

  const imgStyle = (imgSrc: string) => ({
    backgroundImage: `url(${imgSrc})`,
  });

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    variableWidth: true,
    swipeToSlide: true,
    className: styles.blog_slider,
  };

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (Math.abs(e.clientX - tap.x) + Math.abs(e.clientY - tap.y) < 10) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };

  const eventControl = (event: MouseEvent<HTMLButtonElement>) => {
    setTap({ x: event.clientX, y: event.clientY });
  };

  return (
    <section className={styles.blog_section}>
      <div className={styles.section_header}>
        <h3 className={styles.subtitle}>
          <span className={styles.desktop_subtitle}>
            Happening Now at Dance Church
          </span>
          <span className={styles.mobile_subtitle}>On the Blog</span>
        </h3>
        <div className={styles.link_and_arrows}>
          <h4>
            <Link href="/blog">See all Blog Posts</Link>
          </h4>
          <span>
            <button
              onClick={() => {
                sliderRef.current?.slickPrev();
              }}
            >
              <ArrowLeftIcon />
            </button>
            <button
              onClick={() => {
                sliderRef.current?.slickNext();
              }}
            >
              <ArrowRightIcon />
            </button>
          </span>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {articles?.map((article, key) => (
          <div key={key}>
            <Link href={`/blog/${article.uid}`}>
              <button
                draggable="false"
                onMouseDown={eventControl}
                onClick={handleClick}
                role="link"
                tabIndex={key}
              >
                <div className={styles.blogpost_wrapper}>
                  <div className={styles.blogpost}>
                    <figure
                      className={styles.image}
                      style={imgStyle(article.data.hero_image.url)}
                    ></figure>
                    <h3>
                      {DateTime.fromISO(article.last_publication_date)
                        .toLocaleString(DateTime.DATE_SHORT)
                        .replace(/\//gi, ".")}
                    </h3>
                    <PrismicRichText
                      field={article.data.title}
                      components={{
                        heading1({ children }) {
                          return (
                            <h3 key={children[0].key} className={styles.title}>
                              {children}
                            </h3>
                          );
                        },
                      }}
                    />
                  </div>
                </div>
              </button>
            </Link>
          </div>
        ))}{" "}
      </Slider>
    </section>
  );
};
