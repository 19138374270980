import React from "react";
import styles from "./OnlineClassesModule.module.scss";

export const OnlineClassesModule = () => {
  return (
    <section className={styles.classes_section}>
      <div className={styles.classes_wrapper}>
        <h3 className={styles.subtitle}>Online Classes</h3>
        <p>
          Sweat from <span className={styles.hype}>wherever</span> or{" "}
          <span className={styles.hype}>whenever</span>{" "}
          <span className={styles.break}>with Dance Church on-demand.</span>
        </p>
      </div>
    </section>
  );
};
