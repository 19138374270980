import {
  AnyRegularField,
  GroupField,
  ImageField,
  RichTextField,
  SliceZone,
} from "@prismicio/types";
import {
  BecomeATeacherModule,
  TeacherImage,
} from "~/components/home/BecomeATeacherModule";
import { BlogPost, BlogPostsModule } from "~/components/home/BlogPostsModule";
import { Box } from "~/components/layout";
import { DateTime } from "luxon";
import {
  FeaturedTeachersModule,
  Teacher,
} from "~/components/home/FeaturedTeachersModule";
import {
  getDocument,
  getDocumentsByType,
  getNewsArticles,
} from "~/lib/shared/prismic";
import { getInPersonEvents } from "~/lib/client/api/inPersonEvent";
import { GetServerSideProps } from "next";
import { Hero } from "~/components/home/Hero";
import { InPersonEventApi } from "dcgo-contracts";
import { Layout } from "../components/layout/Layout";
import { LiveStreamModule } from "~/components/home/LiveStreamModule";
import { NewsletterLocalModule } from "~/components/home/NewsletterLocalModule";
import { OnDemandModule } from "~/components/home/OnDemandModule";
import { OnlineClassesModule } from "~/components/home/OnlineClassesModule";
import { PressModule } from "~/components/home/PressModule";
import { TeachersTextModule } from "~/components/home/TeachersTextModule";
import {
  Testimonial,
  TestimonialModule,
} from "~/components/home/TestimonialModule";
import {
  UpcomingClassesModule,
  UpcomingEvent,
} from "~/components/home/UpcomingClassesModule";
import React from "react";
import styles from "./Index.module.scss";

/* eslint-disable camelcase */

interface FeaturedEvent {
  custom_image?: AnyRegularField | GroupField | SliceZone;
}

type HomeProps = {
  data: {
    hero_text: RichTextField;
    livestream_module_image: ImageField;
    featured_teachers: Teacher[];
    become_a_teacher_carousel: TeacherImage[];
    testimonials: Testimonial[];
    join_the_community: RichTextField;
  };
  featuredClasses: UpcomingEvent[];
  articles: BlogPost[];
};

/* eslint-enable camelcase */

const Home = ({ data, articles, featuredClasses }: HomeProps) => {
  return (
    <>
      <Layout
        title="Taught by Dancers, Made for Every Body"
        description={
          "An all-levels movement class that offers a fun and inclusive approach to dancing. No prior dance experience necessary."
        }
        canonicalPath="/"
      >
        <style global={true} jsx={true}>{`
          .main {
            max-width: none;
          }
        `}</style>
        <div className={`${"page_wrapper"} ${styles.home_wrapper}`}>
          <Box>
            <Hero heroText={data.hero_text} />
            <UpcomingClassesModule events={featuredClasses} />
            <NewsletterLocalModule />
            <BlogPostsModule articles={articles} />
            <TeachersTextModule />
            <FeaturedTeachersModule teachers={data.featured_teachers} />
            <BecomeATeacherModule images={data.become_a_teacher_carousel} />
            <OnlineClassesModule />
            <OnDemandModule />
            <LiveStreamModule image={data.livestream_module_image} />
            <TestimonialModule
              data={data.testimonials}
              heading={data.join_the_community}
            />
            <PressModule />
          </Box>
        </div>
      </Layout>
    </>
  );
};

export const getServerSideProps: GetServerSideProps = async () => {
  //get featured class data
  /* eslint-disable camelcase */

  const compareEventTimes = (
    a: InPersonEventApi.InPersonEvent,
    b: InPersonEventApi.InPersonEvent
  ) => {
    return DateTime.fromISO(a.startsAt) < DateTime.fromISO(b.startsAt)
      ? -1
      : DateTime.fromISO(a.startsAt) > DateTime.fromISO(b.startsAt)
      ? 1
      : 0;
  };

  const allEvents = await getInPersonEvents();
  const eventsById = allEvents.reduce<
    Record<string, InPersonEventApi.InPersonEvent>
  >((acc, cur) => {
    acc[`${cur.id}`] = cur;
    return acc;
  }, {});

  const featured: InPersonEventApi.InPersonEvent[] = await getDocumentsByType(
    "featured_class"
  )
    .then((data) => {
      const featuredClasses: InPersonEventApi.InPersonEvent[] = [];
      for (let index = 0; index < data.length; index++) {
        const eventId = data[index].uid;

        if (!eventId) {
          console.log("Invalid prismic event id: (", data[index].uid, ")");
          continue;
        }
        const event = eventsById[eventId];
        if (!event) {
          console.log("No matching prismic event found: ", eventId);
          continue;
        }

        const featuredEvent: FeaturedEvent &
          InPersonEventApi.InPersonEvent = Object.assign({}, event, {
          custom_image: data[index].data.custom_image,
        });

        //check if event has passed
        if (DateTime.fromISO(event.startsAt) > DateTime.now()) {
          featuredClasses.push(featuredEvent);
        }
      }
      return featuredClasses;
    })
    .catch((error) => {
      console.log(error);
      return [];
    });

  featured.sort(compareEventTimes);

  const remainingClasses: InPersonEventApi.InPersonEvent[] = [];

  const eventLimit = 12;

  if (featured.length < eventLimit) {
    const remainingClasses: InPersonEventApi.InPersonEvent[] = [];
    for (let index = 0; index < allEvents.length; index++) {
      let alreadyIncluded = false;
      for (let j = 0; j < featured.length; j++) {
        if (allEvents[index].id === featured[j].id) {
          alreadyIncluded = true;
          break;
        }
      }
      if (alreadyIncluded === false) {
        remainingClasses.push(allEvents[index]);
      }
      if (featured.length + remainingClasses.length >= eventLimit) {
        break;
      }
    }
    remainingClasses.sort(compareEventTimes);
  }

  const upcomingClasses = featured.concat(remainingClasses);

  /* eslint-enable camelcase */

  return {
    props: {
      data: await getDocument("home_page"),
      featuredClasses: upcomingClasses,
      articles: (await getNewsArticles()).slice(0, 12),
    },
  };
};

export default Home;
