import { RefObject, useEffect } from "react";

/**
 * Next.js does not support autoFocus: https://github.com/vercel/next.js/discussions/17213
 * Simple hook that uses a ref to do the same thing
 */
export const useAutofocus = (ref: RefObject<HTMLInputElement>): void => {
  useEffect(() => {
    if (ref.current) {
      ref.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
