import { Action, useTracking } from "~/lib/client/tracking";
import { useForm } from "react-hook-form";
import React, { useState } from "react";
import styles from "./NewsletterLocalModule.module.scss";

export const NewsletterLocalModule = () => {
  const [isComplete, setIsComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const tracking = useTracking();

  async function getCityStateFromZip(zipcode: number) {
    // Get the US zip code data from the USPS API.
    const url = `https://usps-zip-codes.deno.dev/${zipcode}`;
    const response = await fetch(url);
    const data = await response.json();

    // Get the city and state from the response data.
    const { city } = data;
    const { state } = data;

    return { city, state };
  }

  const { handleSubmit, setError, register, errors, formState } = useForm({
    mode: "onChange", // Trigger validation on field change
  });

  const signup = async ({
    email,
    zipcode,
    city,
    state,
  }: {
    email: string;
    zipcode: number;
    city?: string;
    state?: string;
  }) => {
    // console.log("Form Data: ", email, zipcode, city, state);

    setIsLoading(true);

    tracking.engagement(Action.LEAD);
    try {
      const response = await fetch("/api/local-signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          email,
          zipcode,
          city: city ? city : null,
          state: state ? state : null,
        }),
      });

      const responseData = await response.json();

      // console.log("Subscription result:", responseData);

      if (responseData.successCount === 1) {
        setIsLoading(false);
        setIsComplete(true);
      } else {
        setIsLoading(false);
        setIsError(true);
      }
    } catch (error) {
      console.error("Subscription failed:", error);
      setIsLoading(false);
    }
  };

  const processZipcode = async ({
    email,
    zipcode,
  }: {
    email: string;
    zipcode: number;
  }) => {
    const { city, state } = await getCityStateFromZip(zipcode);
    if (city && state) {
      // console.log("Success! City and State:", city, state, email, zipcode);
      signup({ email, zipcode, city, state });
    } else {
      signup({ email, zipcode });
    }
  };

  return (
    <div id="localNewsletter" className={styles.newsletter_module}>
      <div className={styles.inner}>
        <div className={styles.copy}>
          <p>
            <span className={styles.white}>Get news in your area.</span>
            <span className={styles.gray}>
              Emails are occasional and private.
            </span>
          </p>
        </div>
        <div className={styles.form}>
          {isError && (
            <p className={styles.form_messaging}>Error, please try again.</p>
          )}
          {isLoading && <p className={styles.form_messaging}>Sending...</p>}
          {isComplete && <p className={styles.form_messaging}>Thank You!</p>}
          {!isLoading && !isComplete && (
            <form onSubmit={handleSubmit(processZipcode)}>
              <label htmlFor="newsletter-email">Your Email</label>
              <input
                id="newsletter-email"
                className={styles.email}
                type="email"
                name="email"
                placeholder="name@domain.com"
                aria-label="Email for newsletter"
                ref={register({
                  required: "Please enter an email",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                    message: "Not a valid email",
                  },
                })}
              />
              <label htmlFor="newsletter-email">Zip Code</label>
              <input
                id="zipcode"
                className={styles.zipcode}
                type="zipcode"
                name="zipcode"
                placeholder="12345"
                aria-label="Zipcode for newsletter"
                ref={register({
                  required: "Please enter an zipcode",
                  pattern: {
                    value: /^[0-9]{5}(?:-[0-9]{4})?$/i,
                    message: "Not a valid zipcode",
                  },
                })}
              />
              <button disabled={!formState.isValid} type="submit">
                Submit
              </button>
              {/* {errors.email && (
                <ErrorMessage>{errors.email.message}</ErrorMessage>
              )} */}
            </form>
          )}
        </div>
      </div>
    </div>
  );
};
