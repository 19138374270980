import React from "react";
import styled from "styled-components";

export type StatusLineColor = "white" | "pizzazz" | "red";

const StatusBox = styled.div<{ statusColor: StatusLineColor }>((props) => ({
  backgroundColor: props.theme.colors[props.statusColor],
  color: props.statusColor === "red" ? "white" : "black",
  borderRadius: "2px",
  display: "inline-block",
  padding: "2px 5px",
  verticalAlign: "middle",
  fontSize: "18px",
  fontWeight: 900,
  letterSpacing: "-0.02em",
  lineHeight: 1.33,
}));

const StatusDetail = styled.div(({ theme }) => ({
  backgroundColor: "transparent",
  color: theme.colors.white,
  display: "inline-block",
  verticalAlign: "middle",

  fontFamily: "GT America mono",
  fontFeatureSettings: `"ss03" on`,
  fontSize: "12px",
  fontStyle: "normal",
  fontWeight: 500,
  letterSpacing: "0.01em",
  lineHeight: 1.25,
  marginLeft: 0,
  marginTop: "10px",
  textTransform: "uppercase",

  width: "100%",

  [theme.mediaQueries.tablet]: {
    marginLeft: "2em",
    marginTop: 0,
    width: "auto",
  },
}));

export const StatusLine = ({
  statusText,
  statusColor,
  statusDetail,
}: {
  statusText: string;
  statusColor: StatusLineColor;
  statusDetail: string | undefined;
}) => {
  return (
    <div className="status_line">
      <StatusBox statusColor={statusColor}>{statusText}</StatusBox>
      {statusDetail && <StatusDetail>{statusDetail}</StatusDetail>}
    </div>
  );
};
