import GoopIcon from "images/press-module-logos/Goop-logo.svg";
import LATimesIcon from "images/press-module-logos/LA-times-logo.svg";
import React from "react";
import styles from "./PressModule.module.scss";
import VanityFairIcon from "images/press-module-logos/Vanity_Fair_1.svg";
import WiredIcon from "images/press-module-logos/wired.svg";

export const PressModule = () => {
  return (
    <section className={styles.press_section}>
      <div className={styles.press_wrapper}>
        <div className={styles.press_item}>
          <span className={styles.wired}>
            <WiredIcon />
          </span>
          <p>&lsquo;The best dance workout&rsquo;</p>
        </div>
        <div className={styles.press_item}>
          <span className={styles.goop}>
            <GoopIcon />
          </span>
          <p>
            &lsquo;Dancer&apos;s high should <br /> really be a thing &rsquo;
          </p>
        </div>
        <div className={styles.press_item}>
          <span className={styles.la_times}>
            <LATimesIcon />
          </span>
          <p>&lsquo;Let it all out in the dark&rsquo;</p>
        </div>
        <div className={styles.press_item}>
          <span className={styles.vanity_fair}>
            <VanityFairIcon />
          </span>
          <p>&lsquo;A state of therapeutic release&rsquo;</p>
        </div>
      </div>
    </section>
  );
};
