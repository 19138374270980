import { DateTime } from "luxon";
import { ImageField, RichTextField } from "@prismicio/types";
import { PrismicRichText } from "@prismicio/react";
import React from "react";
import styles from "./TestimonialModule.module.scss";

const imgStyle = (imgSrc: string | null | undefined) => ({
  backgroundImage: `url(${imgSrc})`,
});

type TestimonialProps = {
  data: Testimonial[];
  heading: RichTextField;
};

export type Testimonial = {
  avatar: ImageField;
  date: string;
  quote: RichTextField;
  user: RichTextField;
};

export const TestimonialModule = ({ data, heading }: TestimonialProps) => {
  // console.log("testimonials: ", data);
  return (
    <section className={styles.testimonial_section}>
      <div className={styles.testimonial_wrapper}>
        <div className={styles.copy}>
          <h3 className={styles.subtitle}>Join the Community</h3>
          <h1>
            {/* <span className={styles.hype}>200,000 people</span> have taken Dance
            Church classes to-date in{" "}
            <span className={styles.hype}>40 cities</span> across the US and
            globally. */}
            <PrismicRichText field={heading} />
          </h1>
        </div>
        <div className={styles.testimonial_slider}>
          {data.map((testimonial, key) => (
            <div key={key} className={styles.testimonial_slide_wrapper}>
              <div className={styles.testimonial_slide}>
                <figure
                  style={imgStyle(testimonial.avatar.url)}
                  className={styles.image}
                ></figure>
                <div className={styles.copy}>
                  <p className={styles.date}>
                    {DateTime.fromISO(testimonial.date)
                      .toLocaleString(DateTime.DATE_SHORT)
                      .replace(/\//gi, ".")}
                  </p>
                  <PrismicRichText
                    field={testimonial.quote}
                    components={{
                      heading1({ children }) {
                        return <p key={children[0].key}>{children}</p>;
                      },
                    }}
                  />
                  <PrismicRichText
                    field={testimonial.user}
                    components={{
                      heading1({ children }) {
                        return (
                          <p key={children[0].key} className={styles.hype}>
                            {children}
                          </p>
                        );
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className={styles.mobile_testimonials}>
          {data.map((testimonial, key) => (
            <div key={key} className={styles.testimonial_slide_wrapper}>
              <div className={styles.testimonial_slide}>
                <figure
                  style={imgStyle(testimonial.avatar.url)}
                  className={styles.image}
                ></figure>
                <div className={styles.copy}>
                  <p>
                    {DateTime.fromISO(testimonial.date)
                      .toLocaleString(DateTime.DATE_SHORT)
                      .replace(/\//gi, ".")}
                  </p>
                  <PrismicRichText
                    field={testimonial.quote}
                    components={{
                      heading1({ children }) {
                        return <p key={children[0].key}>{children}</p>;
                      },
                    }}
                  />
                  <PrismicRichText
                    field={testimonial.user}
                    components={{
                      heading1({ children }) {
                        return (
                          <p key={children[0].key} className={styles.hype}>
                            {children}
                          </p>
                        );
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};
