import { Action, Label, useTracking } from ".";
import { useEffect } from "react";

export const useTrackView = (action: Action, label: Label) => {
  const tracking = useTracking();

  useEffect(() => {
    tracking.engagement(action, label);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [action, label]);
};
