import { ImageField } from "@prismicio/types";
import { PrismicDocumentData } from "~/lib/shared/prismic";
import Link from "next/link";
import React from "react";
import Slider from "react-slick";
import styles from "./BecomeATeacherModule.module.scss";

const settings = {
  dots: false,
  infinite: true,
  autoplay: true,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  className: styles.become_a_teacher_slider,
};

export type TeacherImage = {
  image: ImageField;
};

interface TeacherImages extends PrismicDocumentData {
  images: TeacherImage[];
}

export const BecomeATeacherModule = ({ images }: TeacherImages) => {
  const imgStyle = (imgSrc: string) => ({
    backgroundImage: `url(${imgSrc})`,
  });

  return (
    <section className={styles.onboarding_module}>
      <div className={styles.copy}>
        <h2>
          <span className={styles.hype}>Join Us </span>
          Become a Teacher
        </h2>
        <p>
          Dance Church offers the tools to help build your community in dance.
          Applications now open, bring the sweat to your city.
        </p>
        <Link href="/become-a-teacher">
          <a>
            Apply Now <span>→</span>
          </a>
        </Link>
      </div>
      <Slider {...settings}>
        {images.map(({ image }, key) => {
          if (image.url) {
            return (
              <div key={key}>
                <figure style={imgStyle(image.url)} key={key}></figure>
              </div>
            );
          }
        })}
      </Slider>
    </section>
  );
};
