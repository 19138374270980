import { getCountdownFormatFromDiff } from "~/lib/client/time";
import { HeroLayout } from "./HeroLayout";
import { UpcomingLiveStatus } from "~/lib/client/live-status";
import React, { FC } from "react";

export const OnAirSoonHero: FC<{ liveStatus: UpcomingLiveStatus }> = ({
  liveStatus,
}) => {
  const statusDetail = getCountdownFormatFromDiff(liveStatus.timeUntilLive);
  return (
    <HeroLayout
      statusText="On-Air Soon"
      statusColor="red"
      statusDetail={statusDetail}
      video={liveStatus.video}
    >
      Live class is about to start. Join the countdown, stretch it out.
    </HeroLayout>
  );
};
