import { ImageField, RichTextField } from "@prismicio/types";
import { PrismicRichText } from "@prismicio/react";
import ArrowLeftIcon from "~/images/arrow-left-circle.svg";
import ArrowRightIcon from "~/images/arrow-right-circle.svg";
import Link from "next/link";
import React, { useRef } from "react";
import Slider from "react-slick";
import styles from "./FeaturedTeachersModule.module.scss";

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  variableWidth: true,
  className: styles.teachers_slider,
};

export type Teacher = {
  avatar: ImageField;
  name: RichTextField;
  description: RichTextField;
  hometown: RichTextField;
  slug: string;
};

type FeaturedTeacherProps = {
  teachers: Teacher[];
};

export const FeaturedTeachersModule = ({ teachers }: FeaturedTeacherProps) => {
  // console.log(teachers);
  const imgStyle = (imgSrc: string | null | undefined) => ({
    backgroundImage: `url(${imgSrc})`,
  });

  const sliderRef = useRef<Slider | null>(null);

  if (!teachers) {
    return null;
  }

  return (
    <section className={styles.featured_teachers_section}>
      <div className={styles.header}>
        <h2 className={styles.subtitle}>Taught By Dancers</h2>
        <div className={styles.arrows}>
          <h4>
            <Link href="/teachers">See all Teachers</Link>
          </h4>
          <span>
            <button
              onClick={() => {
                sliderRef.current?.slickPrev();
              }}
            >
              <ArrowLeftIcon />
            </button>
            <button
              onClick={() => {
                sliderRef.current?.slickNext();
              }}
            >
              <ArrowRightIcon />
            </button>
          </span>
        </div>
      </div>

      <Slider ref={sliderRef} {...settings}>
        {teachers?.map((teacher, key) => (
          <div key={key} className={styles.teacher_wrapper}>
            <div className={styles.teacher}>
              <figure
                style={imgStyle(teacher.avatar.url)}
                className={styles.image}
              ></figure>
              <div className={styles.copy}>
                <PrismicRichText
                  field={teacher.hometown}
                  components={{
                    paragraph({ children }) {
                      return (
                        <span key={children[0].key} className={styles.hometown}>
                          {children}
                        </span>
                      );
                    },
                  }}
                />
                <PrismicRichText
                  field={teacher.name}
                  components={{
                    paragraph({ children }) {
                      return (
                        <h3 key={children[0].key} className={styles.name}>
                          {children}
                        </h3>
                      );
                    },
                  }}
                />
                <PrismicRichText
                  field={teacher.description}
                  components={{
                    paragraph({ children }) {
                      return (
                        <h3
                          key={children[0].key}
                          className={styles.description}
                        >
                          {children}
                        </h3>
                      );
                    },
                  }}
                />
                <Link href={`/${teacher?.slug}`}>
                  <a className={styles.link}>See Classes →</a>
                </Link>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className={styles.mobile_teachers}>
        {teachers?.map((teacher, key) => (
          <div className={styles.teacher} key={key}>
            <figure
              style={imgStyle(teacher.avatar.url)}
              className={styles.image}
            ></figure>
            <div className={styles.copy}>
              <PrismicRichText
                field={teacher.hometown}
                components={{
                  paragraph({ children }) {
                    return (
                      <span key={children[0].key} className={styles.hometown}>
                        {children}
                      </span>
                    );
                  },
                }}
              />
              <PrismicRichText
                field={teacher.name}
                components={{
                  heading1({ children }) {
                    return (
                      <h3 key={children[0].key} className={styles.name}>
                        {children}
                      </h3>
                    );
                  },
                }}
              />
              <PrismicRichText
                field={teacher.description}
                components={{
                  paragraph({ children }) {
                    return (
                      <h3 key={children[0].key} className={styles.name}>
                        {children}
                      </h3>
                    );
                  },
                }}
              />
              <Link href={`/${teacher?.slug}`}>
                <a className={styles.link}>See Classes →</a>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};
