import { AssetApi } from "dcgo-contracts";
import { ForwardText, Link } from "~/components/layout";
import { navHeight } from "~/components/layout/SiteHeader";
import { SectionTitle } from "../SectionTitle";
import { StatusLine, StatusLineColor } from "./StatusLine";
import React, { FC } from "react";
import styled from "styled-components";

const Section = styled.section({
  alignItems: "center",
  display: "flex",
  height: "60vh",
  flexDirection: "column",
  justifyContent: "center",
  marginTop: -navHeight,
  overflow: "hidden",
  position: "relative",
});

const BlurredBackground = styled.div<{ backgroundImage: string }>(
  ({ backgroundImage }) => ({
    background: `linear-gradient(359.64deg, #000000 0%, rgba(0, 0, 0, 0) 29.67%), center / cover no-repeat url("${backgroundImage}")`,
    bottom: 0,
    filter: "blur(10px)",
    height: "100%",
    left: 0,
    position: "absolute",
    right: 0,
    top: 0,
  })
);

const TextContainer = styled.div(({ theme }) => ({
  left: 0,
  position: "absolute",
  right: 0,

  paddingLeft: `${theme.gutters.mobile}`,
  paddingRight: `${theme.gutters.mobile}`,
  [theme.mediaQueries.tablet]: {
    paddingLeft: "10%",
    paddingRight: theme.gutters.desktop,
  },
  [theme.mediaQueries.desktop]: {
    paddingLeft: "20%",
  },
}));

const Title = styled(SectionTitle)(({ theme }) => ({
  marginBottom: theme.space[4],
}));

const HeroContent = styled.p(({ theme }) => ({
  color: theme.colors.white,
  marginBottom: theme.space[5],
}));

interface HeroLayoutProps {
  statusText: string;
  statusColor: StatusLineColor;
  statusDetail?: string;
  video: AssetApi.Asset;
}

export const HeroLayout: FC<HeroLayoutProps> = ({
  statusText, // like "Next Class" or "Today"
  statusColor, // see HeroStatusColor enum
  statusDetail, // like "18 H / 10 M / 45 S" (optional)
  video,
  children, // typically, the body copy itself
}) => (
  <Section>
    <BlurredBackground backgroundImage={video.thumbnailUrl} />
    <TextContainer>
      <StatusLine
        statusText={statusText}
        statusColor={statusColor}
        statusDetail={statusDetail}
      />
      <Title as="h1">
        {video.title}
        <br />
        with {video.teacher.name}
      </Title>
      <HeroContent>{children}</HeroContent>
      <Link variant="pill" href={`/live`}>
        <ForwardText>Join the LIVE stream now</ForwardText>
      </Link>
    </TextContainer>
  </Section>
);
