/* eslint-disable @typescript-eslint/no-explicit-any */
import { APIErrors } from "dcgo-contracts";
import { APIResponse } from ".";
import { ConfigInterface, default as realUseSWR, responseInterface } from "swr";

/** The type of a SWR fetcher function. */
type Fetcher<T, D = any> = (data: D | D[]) => Promise<T>;

/** Takes a singleton or an array and always returns an array. */
const forceArray = <T>(t: T | T[]): T[] => {
  return Array.isArray(t) ? t : [t];
};

/** An Error class that includes API errors. */
export class FetcherError extends Error {
  errors: APIErrors;

  constructor(message: string, errors: APIErrors) {
    super(message);
    this.errors = errors;
  }
}

/** Construct an SWR fetcher method from an arbitrary strongly typed API method. */
const fetchWith = <T, D = any>(
  method: (...data: D[]) => Promise<APIResponse<T>>
): Fetcher<T, D> => {
  return async (data: D | D[]): Promise<T> => {
    const response = await method(...forceArray(data));
    if (!response.ok) {
      throw new FetcherError("Fetcher failure", response.errors);
    }
    return response.data;
  };
};

/** A wrapper around Vercel's useSWR that understands the weirdnesses of our API. */
export const useSWR = <T, D = any>(
  key: string | null,
  method: (...data: D[]) => Promise<APIResponse<T>>,
  options?: ConfigInterface<T, APIErrors, any>
): responseInterface<T, APIErrors> =>
  realUseSWR<T, APIErrors>(key, fetchWith(method), options);
