import { DateTime } from "luxon";
import { ImageField } from "@prismicio/types";
import ArrowLeftIcon from "~/images/arrow-left-circle.svg";
import ArrowRightIcon from "~/images/arrow-right-circle.svg";
import Link from "next/link";
import React, { MouseEvent, useRef, useState } from "react";
import Slider from "react-slick";
import styles from "./UpcomingClassesModule.module.scss";

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  variableWidth: true,
  className: styles.class_slider,
};

/* eslint-disable camelcase */

type tapCoordinates = {
  x: number;
  y: number;
};

export type UpcomingEvent = {
  custom_image?: ImageField;
  id: string;
  startsAt: string;
  openPlatform: boolean;
  teacher: {
    avatarUrl: string;
    name: string;
  };
  venue: {
    city: string;
    name: string;
  };
};

type UpcomingClassesProps = {
  events: UpcomingEvent[] | null;
};

/* eslint-enable camelcase */

const upcomingClassDateTime = (datetime: string) => {
  let result = "";
  const dayofWeek = DateTime.fromISO(datetime)
    .toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)
    .replace(/\//gi, ".")
    .split(",")[0];
  const date = DateTime.fromISO(datetime)
    .toLocaleString(DateTime.DATE_SHORT)
    .split("/");
  const time = DateTime.fromISO(datetime)
    .toLocaleString(DateTime.TIME_WITH_SHORT_OFFSET)
    .split(" ");
  const clock = time[0].split(":");

  const resultTime = `${clock[0]}${
    clock[1] === "00" ? "" : `:${clock[1]}`
  }${time[1].substring(0, 1).toLowerCase()} ${
    time[2].substring(0, 1) + time[2].substring(time[2].length - 1)
  }`;
  const resultDate = `${date[0]}.${date[1]}`;

  result = `${dayofWeek} / ${resultDate} / ${resultTime}`;

  return result;
};

export const UpcomingClassesModule = ({ events }: UpcomingClassesProps) => {
  // console.log(events);
  const sliderRef = useRef<Slider | null>(null);
  const imgStyle = (imgSrc: string | null | undefined) => ({
    backgroundImage: `url(${imgSrc})`,
  });
  const [tap, setTap] = useState<tapCoordinates>({ x: 0, y: 0 });

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (Math.abs(e.clientX - tap.x) + Math.abs(e.clientY - tap.y) < 10) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };

  const eventControl = (event: MouseEvent<HTMLButtonElement>) => {
    setTap({ x: event.clientX, y: event.clientY });
  };

  if (!events) {
    return null;
  }

  return (
    <section className={styles.upcoming_section}>
      <div className={styles.section_header}>
        <h3 className={styles.subtitle}>Upcoming Classes</h3>
        <div className={styles.link_and_arrows}>
          <h4>
            <Link href="/irl">See all Classes</Link>
          </h4>
          <span>
            <button
              onClick={() => {
                sliderRef.current?.slickPrev();
              }}
            >
              <ArrowLeftIcon />
            </button>
            <button
              onClick={() => {
                sliderRef.current?.slickNext();
              }}
            >
              <ArrowRightIcon />
            </button>
          </span>
        </div>
      </div>
      <Slider ref={sliderRef} {...settings}>
        {events?.map((classData, key) => {
          if (classData.openPlatform) return null;
          if (classData.custom_image) {
            return (
              <div key={key}>
                <Link href={`/irl/${classData.id}`}>
                  <button
                    draggable="false"
                    onMouseDown={eventControl}
                    onClick={handleClick}
                    role="link"
                    tabIndex={key}
                  >
                    <div className={styles.upcoming_class_wrapper}>
                      <div
                        className={styles.upcoming_class}
                        style={imgStyle(classData.custom_image.url)}
                      >
                        <div className={styles.gradient}></div>
                        <span className={styles.city_tag}>
                          {classData.venue.city}
                        </span>
                        <div className={styles.copy}>
                          <p>{upcomingClassDateTime(classData.startsAt)}</p>
                          <p>Dance with {classData.teacher.name}</p>
                          <p>at {classData.venue.name}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </Link>
              </div>
            );
          } else {
            return (
              <div key={key}>
                <Link href={`/irl/${classData.id}`}>
                  <button
                    draggable="false"
                    onMouseDown={eventControl}
                    onClick={handleClick}
                    role="link"
                    tabIndex={key}
                  >
                    <div className={styles.upcoming_class_wrapper}>
                      <div
                        className={styles.upcoming_class}
                        style={imgStyle(classData.teacher.avatarUrl)}
                      >
                        <div className={styles.gradient}></div>
                        <span className={styles.city_tag}>
                          {classData.venue.city}
                        </span>
                        <div className={styles.copy}>
                          <p>{upcomingClassDateTime(classData.startsAt)}</p>
                          <p>Dance with {classData.teacher.name}</p>
                          <p>at {classData.venue.name}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </Link>
              </div>
            );
          }
        })}
      </Slider>
    </section>
  );
};
