/* eslint-disable no-irregular-whitespace */
import { Label } from "~/lib/client/tracking";
import { PrismicRichText } from "@prismicio/react";
import { RequireMembership } from "../../account/RequireMembership";
// import { useAccount } from "~/lib/client/account";
import { RichTextField } from "@prismicio/types";
import React, { useState } from "react";
import styles from "./DefaultHeroModule.module.scss";

type DefaultHeroProps = {
  heroText: RichTextField;
};

export const DefaultHero = ({ heroText }: DefaultHeroProps) => {
  // const [{ permissions }] = useAccount();
  const [showMembershipModal, setShowMembershipModal] = useState<boolean>(
    false
  );

  return (
    <section className={styles.hero_section}>
      <div className={styles.hero_wrapper}>
        <h3 className={styles.subtitle}>Coast to Coast</h3>
        <PrismicRichText field={heroText} />
      </div>
      {showMembershipModal && (
        <RequireMembership
          analyticsContext={Label.HERO}
          dismissable
          onClose={() => setShowMembershipModal(false)}
        />
      )}
    </section>
  );
};
