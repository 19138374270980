import { Button } from "../layout/Button";
import { theme } from "../../theme";
import { useCaptureEscapeKey } from "~/lib/client/useCaptureEscapeKey";
import { usePreventGlobalScrolling } from "../layout/ScrollFix";
import CloseIcon from "~/images/close.svg";
import React from "react";
import styled from "styled-components";

const { colors } = theme;

interface ModalStyleProps {
  wide?: boolean;
  hype?: boolean;
  pizzazz?: boolean;
  dismissable?: boolean;
}

const Overlay = styled.div<ModalStyleProps>`
  align-items: center;
  backdrop-filter: blur(4px);
  /* background: ${colors.ninetyblack}; */
  background: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.dismissable ? colors.ninetyblack : colors.black};
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 10000;
  max-height: 100vh;
`;

const ModalLayout = styled.div<ModalStyleProps>`
  box-shadow: 5px 10px rgba(0, 0, 0, 0.1);
  overflow: auto;
  padding: 1.5rem 1rem;

  position: relative;

  text-align: left;
  width: 100%;

  max-height: 100%;
  max-width: ${(props) => (props.wide ? "960px" : "35rem")};

  background-color: ${(props) =>
    // eslint-disable-next-line no-nested-ternary
    props.hype ? colors.hype : props.pizzazz ? colors.pizzazz : "transparent"};

  color: ${(props) =>
    props.hype || props.pizzazz ? colors.black : colors.white};

  -webkit-overflow-scrolling: touch;
`;

const CloseButtonWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 1.5rem;
`;

export const Modal: React.FC<
  ModalStyleProps & {
    dismissable?: boolean;
    onClose?: () => void;
    label: string;
  }
> = ({
  children,
  dismissable,
  label,
  onClose,
  wide = false,
  hype = false,
  pizzazz = false,
}) => {
  usePreventGlobalScrolling();
  useCaptureEscapeKey(() => {
    if (dismissable && onClose) onClose();
  });
  return (
    <Overlay dismissable={dismissable}>
      <ModalLayout
        hype={hype}
        pizzazz={pizzazz}
        wide={wide}
        role="dialog"
        aria-label={label}
      >
        {dismissable ? (
          <CloseButtonWrapper>
            <Button transparent onClick={onClose}>
              <CloseIcon />
            </Button>
          </CloseButtonWrapper>
        ) : null}
        {children}
      </ModalLayout>
    </Overlay>
  );
};
