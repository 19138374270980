import { Action, Label, useTracking } from "~/lib/client/tracking";
import { AuthErrorCode, SignUpRequest } from "~/lib/client/firebase";
import { Box, Words } from "design-system";
import { DateTime } from "luxon";
import { IrlEvent } from "../irl/IrlEvent";
import { useAccount } from "~/lib/client/account";
import { useAsync } from "~/lib/client/loading";
import { useAutofocus } from "~/lib/client/forms";
import { useForm } from "react-hook-form";
import { useTrackView } from "~/lib/client/tracking/useTrackView";
import Link from "next/link";
import React, { useRef } from "react";
import styled from "styled-components";
import styles from "./SignupForm.module.scss";

interface SignupFormProps {
  onLogin: () => void;
  onSignedUp?: () => void;
  analyticsContext: Label;
  mode?: "default" | "gift";
  missionText?: string;
  accountText?: string;
  ticketCount?: number;
  event?: IrlEvent;
}

const Heading = styled.p(({ theme }) => ({
  fontWeight: theme.fontWeights.black,
  paddingBottom: theme.space[2],
}));

const SignupForm: React.FC<SignupFormProps> = ({
  onLogin,
  onSignedUp = () => {},
  analyticsContext,
  mode = "default",
  missionText,
  accountText,
  ticketCount,
  event,
}) => {
  const nameFieldRef = useRef<HTMLInputElement | null>(null);
  useAutofocus(nameFieldRef);
  const { register, handleSubmit, setError, errors } = useForm(); // initialize the hook
  const [{ isLoading: isAccountLoading }, account] = useAccount();
  const { isLoading, handleStart, handleFail } = useAsync();

  useTrackView(Action.VIEWED_SIGN_UP_FORM, analyticsContext);
  const track = useTracking();

  const signUp = async (data: SignUpRequest) => {
    if (isAccountLoading) {
      return;
    }
    handleStart();
    const result = await account.signUp(data);
    if (result.ok) {
      track.registered({ label: analyticsContext });
      onSignedUp();
    } else {
      handleFail();
      if (result.error.code === AuthErrorCode.ERROR_DUPLICATE) {
        setError("email", {
          type: "manual",
          message: "This email is already registered. Try logging in.",
        });
      } else if (result.error.code === AuthErrorCode.ERROR_INVALID_EMAIL) {
        setError("email", {
          type: "manual",
          message: "This email address is invalid.",
        });
      } else {
        setError("email", {
          type: "manual",
          message: "Failed to sign up. Please try again or contact support.",
        });
      }
    }
  };

  const headings = {
    default: <>Create Your Account</>,
    gift: <>Create your account</>,
  };

  const submitTexts = {
    default: "Let's Go",
    payment: "Next",
    gift: "Next",
  };

  const startsAt = event
    ? DateTime.fromISO(event.startsAt).toFormat("EEE, MMM d @ t")
    : null;

  return (
    <form
      className={`${styles.signup_form} form`}
      onSubmit={handleSubmit(signUp)}
    >
      {ticketCount ? (
        <>
          <div className={styles.event_details}>
            <Box
              display="flex"
              flexDirection={["column", "row"]}
              justifyContent="space-between"
            >
              <Box mb={["m", "none"]}>
                <Words variant="bodyHeaderBold">Your total</Words>
                <Words variant="heroSmall">
                  $
                  {ticketCount && event
                    ? ticketCount * event.publicTicketPrice
                    : null}
                </Words>
              </Box>
            </Box>
            <Box
              p="s"
              borderColor="gray1"
              borderWidth={1}
              borderStyle="solid"
              borderRadius={5}
            >
              <Words variant="bodySmallNormal">{startsAt}</Words>
              <Words variant="bodySmallNormal">{event?.venue?.name}</Words>
              <Words variant="bodySmallNormal">
                {event?.venue?.city}, {event?.venue?.state}
              </Words>
            </Box>
          </div>
          <div className={styles.instructions}>
            <Heading>Contact Information</Heading>
          </div>
        </>
      ) : (
        <div className="form__header">
          <h1 className="heading_h4">{headings[mode]}</h1>
        </div>
      )}

      <div className="login-wrapper__container__content__paragraph__mobile">
        <p>{missionText}</p>
        <p>{accountText}</p>
      </div>
      <div className={ticketCount ? "form__row" : "form__row form__row--first"}>
        <label className="form__row__label" htmlFor="firstName">
          First Name
        </label>
        <div className="form__row__value">
          <input
            tabIndex={0}
            className="form__input"
            id="firstName"
            type="text"
            name="firstName"
            placeholder="Firstname"
            ref={(element) => {
              nameFieldRef.current = element;
              register({
                required: "Please enter a first name",
              })(element);
            }}
          />
          {errors.firstName && (
            <div className="form__error">{errors.firstName.message}</div>
          )}
        </div>
      </div>
      <div className="form__row">
        <label className="form__row__label" htmlFor="lastName">
          Last Name
        </label>
        <div className="form__row__value">
          <input
            tabIndex={0}
            className="form__input"
            id="lastName"
            type="text"
            name="lastName"
            placeholder="Lastname"
            ref={register({
              required: "Please enter a last name",
            })}
          />
          {errors.lastName && (
            <div className="form__error">{errors.lastName.message}</div>
          )}
        </div>
      </div>
      <div className="form__row">
        <label className="form__row__label" htmlFor="email">
          Email
        </label>
        <div className="form__row__value">
          <input
            tabIndex={0}
            className="form__input"
            id="email"
            type="email"
            name="email"
            autoComplete="email"
            placeholder="email@domain.com"
            ref={register({
              required: "Please enter an email",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Not a valid email",
              },
            })}
          />
          {errors.email && (
            <div className="form__error">{errors.email.message}</div>
          )}
        </div>
      </div>
      <div className="form__row">
        <label className="form__row__label" htmlFor="password">
          Password
        </label>
        <div className="form__row__value">
          <input
            tabIndex={0}
            className="form__input"
            id="password"
            type="password"
            name="password"
            autoComplete="new-password"
            placeholder="Create a password"
            ref={register({
              required: "Please enter a password",
              minLength: {
                value: 6,
                message: "Should have at least 6 characters",
              },
            })}
          />
          {errors.password && (
            <div className="form__error">{errors.password.message}</div>
          )}
        </div>
      </div>
      {!ticketCount && (
        <>
          <div className="form__row form__row--last form__row--legal first">
            <input
              tabIndex={0}
              id="newsletter"
              className="form__input"
              name="newsletter"
              type="checkbox"
              ref={register}
            />
            <label
              className="form__row__label form__row__label--checkbox"
              htmlFor="newsletter"
            >
              Sign me up for the mailing list
            </label>
          </div>

          <div className="form__row form__row--last form__row--legal">
            <input
              tabIndex={0}
              id="terms"
              className="form__input"
              name="terms"
              type="checkbox"
              // ref={register({
              //   required:
              //     "You must agree to the terms & conditions before continuing.",
              // })}
            />
            <label
              className="form__row__label form__row__label--checkbox"
              htmlFor="terms"
            >
              By checking this box, you agree to our{" "}
              <Link href="/legal/terms">
                <a target="_blank" tabIndex={-1}>
                  Terms of Service
                </a>
              </Link>
              . See our{" "}
              <Link href="/legal/privacy">
                <a target="_blank" tabIndex={-1}>
                  Privacy Policy
                </a>
              </Link>{" "}
              for information about our privacy practices.
            </label>
          </div>
        </>
      )}

      <div className="form__row form__row--between form__row--last border-top">
        <button className="pill_btn" type="submit" tabIndex={0}>
          {isLoading && ticketCount
            ? "Loading..."
            : isLoading
            ? "Signing Up..."
            : ticketCount
            ? "Next"
            : submitTexts[mode]}
        </button>
        <span className="form-text explainer-text">
          Already have an account?{" "}
          <button className="link" onClick={onLogin} type="button">
            Log In
          </button>
        </span>
      </div>
      {errors.terms && (
        <div className="form__error global_error">{errors.terms.message}</div>
      )}
      <div className="login-wrapper__container__content__paragraph">
        <p>{missionText}</p>
        <p>{accountText}</p>
      </div>
    </form>
  );
};

export default SignupForm;
