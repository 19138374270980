import { APIResponse, get, post, put, resolveApiResponse } from ".";
import { ViewApi } from "dcgo-contracts";

export const createView = (
  assetId: number,
  data: ViewApi.CreateViewRequest
): Promise<APIResponse<ViewApi.ViewResponse>> =>
  put(`/assets/${assetId}/views`, data);

export const updateView = (
  assetId: number,
  viewId: number,
  data: ViewApi.UpdateViewRequest
): Promise<APIResponse<ViewApi.ViewResponse>> =>
  post(`/assets/${assetId}/views/${viewId}`, data);

export const getRecentViewers = (
  assetId: number
): Promise<ViewApi.RecentViewers> =>
  resolveApiResponse<ViewApi.RecentViewers>(
    get(`/assets/${assetId}/views/recent`)
  );
