import React from "react";
import styles from "./TeachersTextModule.module.scss";

export const TeachersTextModule = () => {
  return (
    <section className={styles.teachers_text_section}>
      <div className={styles.teachers_hero_wrapper}>
        <h3 className={styles.subtitle}>Dance Church Teachers</h3>
        <h1>
          Performing artists, choreographers, movers and makers —{" "}
          <span className={styles.mobile_hype}>meet the people</span>{" "}
          <span className={styles.hype}>who make you sweat.</span>
        </h1>
      </div>
    </section>
  );
};
