import { Action, Label } from "~/lib/client/tracking";
import { AssetApi } from "dcgo-contracts";
import { Duration } from "luxon";
import { get } from "~/lib/client/api";
import { Link } from "../layout";
import { useSWR } from "~/lib/client/api/swr";
import ArrowLeftIcon from "~/images/left-arrow-circle-small.svg";
import ArrowRightIcon from "~/images/right-arrow-circle-small.svg";
import React, { MouseEvent, useRef, useState } from "react";
import Slider from "react-slick";
import styles from "./OnDemandModule.module.scss";

const settings = {
  dots: false,
  infinite: false,
  arrows: false,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  draggable: true,
  variableWidth: true,
  className: styles.on_demand_slider,
};

const imgStyle = (imgSrc: string) => ({
  backgroundImage: `url(${imgSrc})`,
});
const formatDuration = (video: AssetApi.Asset) => {
  const duration: number = parseInt(
    Duration.fromMillis(video.duration).toFormat("m")
  );
  if (duration > 1 || duration === 0) {
    return `${duration} Minutes`;
  } else {
    return `${duration} Minute`;
  }
};

export const OnDemandModule = () => {
  const { data: demand } = useSWR<AssetApi.DemandResponse>(
    "/videos/demand",
    get
  );
  const sliderRef = useRef<Slider | null>(null);
  const [tap, setTap] = useState({ x: 0, y: 0 });

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (Math.abs(e.clientX - tap.x) + Math.abs(e.clientY - tap.y) < 10) {
      return true;
    } else {
      e.preventDefault();
      return false;
    }
  };

  const eventControl = (event: MouseEvent<HTMLButtonElement>) => {
    setTap({ x: event.clientX, y: event.clientY });
  };

  return (
    <section className={styles.on_demand_section}>
      <div className={styles.header}>
        <div className={styles.left}>
          <h2 className={styles.subtitle}>On-Demand</h2>
          <h2 className={styles.title}>
            Members can dance from anywhere, take streaming classes at home or
            on the go.
          </h2>
        </div>
        <div className={styles.link_and_arrows}>
          <h4>
            <Link
              href="/on-demand"
              trackAction={Action.CLICKED_ON_DEMAND_BROWSE}
              trackLabel={Label.HOME}
              variant="actionSmall"
            >
              Take an On-Demand Class
            </Link>
          </h4>
          <span>
            <button
              onClick={() => {
                sliderRef.current?.slickPrev();
              }}
            >
              <ArrowLeftIcon />
            </button>
            <button
              onClick={() => {
                sliderRef.current?.slickNext();
              }}
            >
              <ArrowRightIcon />
            </button>
          </span>
        </div>
      </div>
      <div className={styles.on_demand_videos}>
        {demand ? (
          <Slider ref={sliderRef} {...settings}>
            {demand.videos.slice(0, 12).map((vodData, key) => (
              <div key={key}>
                <Link href={`/on-demand/${vodData.id}`}>
                  <button
                    draggable="false"
                    onMouseDown={eventControl}
                    onClick={handleClick}
                    role="link"
                    tabIndex={key}
                  >
                    <div className={styles.vod_wrapper}>
                      <div className={styles.vod}>
                        <figure
                          style={imgStyle(vodData.thumbnailUrl)}
                          className={styles.image}
                        ></figure>
                        <div className={styles.details}>
                          <div className={styles.tag_and_duration}>
                            <span className={styles.category}>
                              {vodData.category}
                            </span>
                            <span className={styles.duration}>
                              {formatDuration(vodData)}
                            </span>
                          </div>
                          <p>{vodData.title}</p>
                          <p>with {vodData.teacherName}</p>
                        </div>
                      </div>
                    </div>
                  </button>
                </Link>
              </div>
            ))}
          </Slider>
        ) : null}
      </div>
    </section>
  );
};
