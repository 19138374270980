import { Action, Label } from "~/lib/client/tracking";
import { AuthErrorCode } from "~/lib/client/firebase";
import { useAccount } from "~/lib/client/account";
import { useAsync } from "~/lib/client/loading";
import { useAutofocus } from "~/lib/client/forms";
import { useForm } from "react-hook-form";
import { useTrackView } from "~/lib/client/tracking/useTrackView";
import clsx from "clsx";
import React, { useRef } from "react";

interface LoginFormProps {
  onSignup: () => void;
  onLoggedIn?: () => void;
  analyticsContext: Label;
}

const LoginForm: React.FC<LoginFormProps> = ({
  analyticsContext,
  onSignup,
  onLoggedIn = () => {},
}) => {
  const {
    register,
    handleSubmit,
    setError,
    errors,
    clearErrors,
    getValues,
  } = useForm();
  const {
    isLoading: isLoggingIn,
    handleStart: startLogin,
    handleFail,
  } = useAsync();
  const {
    isLoading: isResetting,
    isComplete,
    handleStart: startReset,
    handleSuccess,
  } = useAsync();
  const [{ isLoading: isAccountLoading }, accountActions] = useAccount();
  const emailFieldRef = useRef<HTMLInputElement | null>(null);
  useAutofocus(emailFieldRef);

  useTrackView(Action.VIEWED_LOG_IN_FORM, analyticsContext);

  const reset = async () => {
    const { email } = getValues();
    if (isAccountLoading) {
      return;
    }
    if (!email) {
      setError("email", {
        type: "manual",
        message: "Enter an email to send your password reset.",
      });
      return;
    }
    startReset();
    await accountActions.sendPasswordReset(email);
    handleSuccess();
  };

  const signIn = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    if (isAccountLoading) {
      return;
    }
    startLogin();
    clearErrors();
    try {
      await accountActions.signIn(email, password, analyticsContext);
      onLoggedIn();
    } catch (error) {
      handleFail();
      if (error.code === AuthErrorCode.ERROR_NO_USER) {
        setError("email", {
          type: "manual",
          message: "No user with this email. Try signing up.",
        });
      } else if (error.code === AuthErrorCode.ERROR_WRONG_PASSWORD) {
        setError("password", {
          type: "manual",
          message: "Invalid password, please try again.",
        });
      } else {
        setError("email", {
          type: "manual",
          message: "Unable to sign in. Please try again.",
        });
      }
    }
  };

  return (
    <form className="form" onSubmit={handleSubmit(signIn)}>
      <div className="form__header">
        <span className="heading_h6">Join Now</span>
        <h1 className="heading_h4">
          Sign in,
          <br />
          let it out.
        </h1>
      </div>
      <div className="form__row form__row--first">
        <label className="form__row__label" htmlFor="email">
          Email
        </label>
        <div className="form__row__value">
          {/* eslint-disable-next-line jsx-a11y/autocomplete-valid */}
          <input
            tabIndex={0}
            className="form__input"
            id="email"
            type="email"
            name="email"
            autoComplete="username"
            placeholder="email@domain.com"
            ref={(element) => {
              emailFieldRef.current = element;
              register({
                required: "Please enter an email",
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                  message: "Not a valid email",
                },
              })(element);
            }}
          />
          {errors.email && (
            <div className="form__error">{errors.email.message}</div>
          )}
        </div>
      </div>
      <div className="form__row">
        <label className="form__row__label" htmlFor="password">
          Password
        </label>
        <div className="form__row__value">
          <input
            tabIndex={0}
            className="form__input"
            id="password"
            type="password"
            name="password"
            autoComplete="current-password"
            placeholder="abc123!@#"
            ref={register({
              required: "Please enter a password",
              minLength: {
                value: 6,
                message: "Should have at least 6 characters",
              },
            })}
          />
          {errors.password && (
            <div className="form__error">{errors.password.message}</div>
          )}
        </div>
      </div>
      <div className="form__row form__row--last form__row--right">
        <span
          className={clsx("form-text", {
            "form-text--success": isComplete,
          })}
        >
          {isComplete ? (
            "Reset email sent!"
          ) : (
            <button
              className="link link--stealth"
              onClick={reset}
              tabIndex={-1}
              type="button"
            >
              {isResetting ? "Sending email..." : "Forgot your password?"}
            </button>
          )}
        </span>
      </div>
      <div className="form__row form__row--between form__row--last">
        <button className="pill_btn" type="submit" tabIndex={0}>
          {isLoggingIn ? "Logging in..." : "Let's Go"}
        </button>
        <span className="form-text explainer-text">
          Don&apos;t have an account?&nbsp;&nbsp;
          <button className="link" onClick={onSignup} type="button">
            Sign Up
          </button>
        </span>
      </div>
    </form>
  );
};

export default LoginForm;
